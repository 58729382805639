import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
/* import layout from '@/layout' */
import DrayManage from '@/router/modules/DrayManage'
import MyRecord from '@/router/modules/MyRecord'
import RateInquiry from '@/router/modules/RateInquiry'
import MyBill from '@/router/modules/MyBill'
import Track from '@/router/modules/Track'
import UserManageRouter from './modules/UserManage'
import PermissionListRouter from './modules/PermissionList'
import RateCard from '@/router/modules/RateCard'

import store from '@/store'

export const Layout = () => import('@/layout/index.vue')
/**
 * 私有路由表
 */
export const privateRoutes = [
  UserManageRouter,
  PermissionListRouter
]

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/login',
    component: () =>
      import('@/views/login/index')
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index'),
        meta: {
          title: 'dashboard',
          icon: 'dashboard'
        }
      },
      {
        path: '/rate',
        name: 'rate',
        component: () => import('@/views/bill/testPage.vue'),
        meta: {
          title: 'rate',
          icon: 'reward'
        }
      },
      {
        path: '/404',
        name: '404',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () =>
          import(/* webpackChunkName: "error-page" */ '@/views/error-page/401')
      }
    ]
  },
  Track,
  RateInquiry,
  DrayManage,
  RateCard,
  MyBill,
  MyRecord
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    store.getters.userInfo &&
    store.getters.userInfo.permission &&
    store.getters.userInfo.permission.menus
  ) {
    const menus = store.getters.userInfo.permission.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  history:
    process.env.NODE_ENV === 'production'
      ? createWebHistory()
      : createWebHashHistory(),
  routes: publicRoutes
})

export default router
