import layout from '@/layout'

export default {
  path: '/drayRC',
  component: layout,
  redirect: '/drayRC/rcList',
  name: 'drayRC',
  meta: { title: 'drayRC', icon: 'article-ranking' },
  children: [
    {
      path: '/drayRC/rcList',
      component: () => import('@/views/ratecard/drayRC'),
      meta: {
        title: 'drayRCList',
        icon: 'article-ranking'
      }
    },
    {
      path: '/drayRC/drayRCEdit/:myParam',
      name: 'drayRCEdit',
      component: () => import('@/views/ratecard/drayRCEdit'),
      props: true,
      meta: {
        title: 'drayRCEdit'
      }
    },
    {
      path: '/chargeCode/legCodeManage',
      name: 'legCodeManage',
      component: () => import('@/views/ratecard/legCodeManage'),
      props: true,
      meta: {
        title: 'legCodeManage'
      }
    },
    {
      path: '/chargeCode/chargeCodeEdit',
      name: 'chargeCodeEdit',
      component: () => import('@/views/ratecard/chargeCodeEdit'),
      props: true,
      meta: {
        title: 'chargeCodeEdit'
      }
    },
    {
      path: '/chargeCode/paramEdit/:dataSourceTag',
      name: 'paramEdit',
      component: () => import('@/views/ratecard/paramEdit'),
      props: true,
      meta: {
        title: 'paramEdit'
      }
    }
  ]
}
