import layout from '@/layout'

export default {
  path: '/quote',
  component: layout,
  redirect: '/rateInquiry/ofQuery',
  name: 'quote',
  meta: { title: 'quote', icon: 'article' },
  children: [
    {
      path: '/rateInquiry/ofQuery',
      component: () =>
        import('@/views/rateinquiry/ofQuery'),
      meta: {
        title: 'ofQuery',
        icon: 'article-ranking'
      }
    },
    {
      path: '/rateInquiry/chbQuote',
      component: () =>
        import('@/views/rateinquiry/chbQuote'),
      meta: {
        title: 'chbQuote',
        icon: 'article-ranking'
      }
    },
    {
      path: '/rateInquiry/drayBatchQuote',
      component: () =>
        import('@/views/rateinquiry/drayBatchQuote'),
      meta: {
        title: 'drayBatchQuote',
        icon: 'fullscreen'
      }
    },
    {
      path: '/rateInquiry/comboQuote',
      component: () =>
        import('@/views/rateinquiry/comboQuote'),
      meta: {
        title: 'comboQuote',
        icon: 'article-ranking'
      }
    },
    {
      path: '/record/quotes',
      component: () =>
        import('@/views/myrecord/quotes'),
      meta: {
        title: 'quotes',
        icon: 'article-ranking'
      }
    }
  ]
}
