export default {
  login: {
    title: 'User Login',
    loginBtn: 'Login',
    usernameRule: 'Username is required',
    passwordRule: 'Password cannot be less than 6 digits',
    desc: `
    Test authority account:<br />
     Provide three kinds of authority accounts:<br />
     1. Super administrator account: super-admin <br />
     2. Administrator account: admin <br />
     3. Test configurable account: test <br />
     The uniform password is: 123456 <br />
     <br />
     Import user account:<br />
     You can log in with the imported username <br />
     The password is unified as: 123456 <br />
     <b>Note: Import user-discriminatory Chinese and English libraries! ! ! ! </b>
    `
  },
  chb: {
    annualBond: 'Annual Bond',
    clearanceBOL: 'Clearance BOL',
    htsLines: 'HTS Lines',
    isfBOL: 'ISF BOL',
    isfBondBOL: 'ISF Bond BOL',
    ogaNum: 'OGA Number'
  },
  chargeCode: {
    legcode: 'LegCode',
    legname: 'LegName',
    legtype: 'LegType',
    legseq: 'LegSeq',
    chargeType: 'ChargeType',
    chargeUOM: 'ChargeUOM',
    chargeDescription: 'Description',
    responsible: 'Responsible'
  },
  route: {
    accounting: 'Accounting',
    address: 'Address Book',
    article: 'article',
    articleRanking: 'ArticleRanking',
    articleCreate: 'ArticleCreate',
    articleDetail: 'ArticleDetail',
    articleEditor: 'ArticleEditor',
    Billing: 'Billing',
    billStatus: 'Bill Status',
    billEditTemp: 'BillEditTemp',
    chargeCodeEdit: 'ChargeCodeEdit',
    comboQuote: 'Combo Quote',
    costRecord: 'Cost Record',
    chbQuote: 'Import Customs',
    chassisRent: 'Chassis Rent',
    dashboard: 'Dashboard',
    dray: 'Dray',
    drayBatchQuote: 'Dray Batch',
    drayBillDetail: 'DrayBillDetail',
    drayDetail: 'Dray Detail',
    drayOrderDetail: 'DrayOrderDetail',
    drayQuote: 'New Order From Quote',
    drayQuotes: 'QuoteList',
    drayOrder: 'Dray Order',
    drayRC: 'Rate Card',
    drayRCEdit: 'RateCard Edit',
    drayRCList: 'Maintenance',
    drayManage: 'New Order From Track',
    driverRecord: 'Driver Record',
    e2eTrack: 'E2ETrack',
    e2eDetail: 'E2EDetail',
    excelImport: 'ExcelImport',
    interimAP: 'Interim AP',
    interimAR: 'Interim AR',
    invoice: 'Invoice',
    invoiceEdit: 'Invoice Edit',
    legCodeManage: 'LegCodeManage',
    myChart: 'Charts',
    manuelOrder: 'manuelOrder',
    ofQuery: 'Ocean Freight',
    order: 'Order',
    orders: 'AllOrders',
    orderManage: 'Order Manage',
    paramEdit: 'ParamEdit',
    profile: 'Profile',
    portInfo: 'PortInfo',
    permissionList: 'PermissionList',
    quote: 'Quote',
    quotes: 'AllQuotes',
    rate: 'Rate',
    rcEditForQuote: 'DraySpotEdit',
    record: 'My Record',
    roleList: 'RoleList',
    smsTrack: 'SMS Track',
    track: 'Track',
    term49: 'Term49 Track',
    user: 'user',
    userManage: 'EmployeeManage',
    userInfo: 'UserInfo'
  },
  rate: {
    adjust: 'Adjust/Bobtail',
    createQuote: 'Create Quote',
    c1: 'FactorOne',
    c2: 'FactorTwo',
    c20GP: 'c20GP',
    c40GP: 'c40GP',
    c40HQ: 'c40HQ',
    c45HQ: 'c45HQ',
    carrier: 'carrier',
    chbSrv: 'CHB Service',
    createOrder: 'Create Order',
    createQuotePDF: 'Create Quote In PDF',
    clientName: 'Client Name',
    csopName: 'CSOP',
    createTime: 'Create Time',
    downloadCSV: 'Download Rates in CSV',
    draySrv: 'Dray Service',
    dynamicTitle: 'Ocean Freight Table',
    editComplete: 'Confirm',
    endDate: 'Expire On',
    getQuote: 'Get Quote',
    generateOrder: 'Generate Shipment Order',
    item: 'Item',
    ipi: 'ipi',
    hisTag: 'Validity Tag',
    l2CSVDownload: 'L2CSVDownload',
    l2Owner: 'L2 Owner',
    l2Tag: 'L2 Tag',
    l3CSVDownload: 'L3CSVDownload',
    l3Owner: 'Contract Owner',
    notes: 'Notes',
    newClient: 'New Client',
    oldClient: 'Old Client',
    Operations: 'Operations',
    oceanRateCap: 'Ocean Freight Quote',
    pageRemark: 'Page Remark',
    payTerm: 'Pay Term',
    pol: 'POL',
    pod: 'POD',
    primeKey: 'PrimeKey',
    projectProfit: 'Projected Profit',
    quoteFrom: 'Quote From',
    quoteTo: 'Quote To',
    quoteType: 'Quote Type',
    quotePayload: 'Quote Payload',
    qty: 'Quantity',
    reQuote: 'Back to Get New Quote',
    remark: 'Remark',
    ratePerUOM: 'Rate Per UOM',
    rcEdit: 'Rate Card Edit',
    sector: 'Sector',
    startDate: 'Valid From',
    uom: 'UOM',
    validity: 'Validity'
  },
  track: {
    ata: 'ATA',
    atd: 'ATD',
    appointTime: 'AppTime',
    attr: 'Attribute',
    basicInfo: 'Basic Information',
    bookId: 'BookId',
    client: 'Client',
    ctnrCbm: 'ctnrCbm',
    ctnrKg: 'ctnrKg',
    ctnrId: 'CtnrID',
    ctnrSize: 'CtnrSize',
    cartons: 'cartons',
    containerInfo: 'ContainerInfo',
    danger: 'DG',
    ddray: 'dDray',
    destSite: 'Dest',
    dCBPStatus: 'CBPStatus',
    dFreightStatus: 'FreightStatus',
    dWHCrdd: 'WHCrdd',
    dWHArrival: 'whArrival',
    dWHEmptyNotify: 'whEptNotify',
    discharge: 'Discharge',
    dischargeTime: 'DischargeTime',
    dTerminalPickupStatus: 'TermPKStatus',
    drayType: 'drayType',
    dynamicTitle: 'Container Track',
    drayServiceInfo: 'DrayServiceInfo',
    drayCompany: 'Dray Company',
    e2eTrackTitle: 'E2E-Track',
    emptyReturnInfo: 'EmptyReturnInfo',
    emptyReturnAppt: 'EmptyReturnAppt',
    emptyReturn: 'EptRtn',
    emptyReturnAppointTime: 'EptRtnApp',
    emptyReturnTime: 'EptRtn',
    emptyPickUpTime: 'EptPK',
    eta: 'ETA',
    etd: 'ETD',
    firmsCode: 'FirmsCode',
    fullGateIn: 'fullGateIn',
    fullGateOut: 'OutGate',
    goodsName: 'GoodsName',
    holdStatus: 'HoldStatus',
    id: 'ID',
    lfd: 'LFD',
    locationAtTerminal: 'LocationAtTerminal',
    mbl: 'MBL',
    normTotal: 'Est Total',
    onRail: 'OnRail',
    offRail: 'OffRail',
    outGateTime: 'OutGateTime',
    oswCompany: 'OSW Company',
    pickupAva: 'PickupAva',
    pol: 'POL',
    pod: 'POD',
    podRegion: 'podRegion',
    relationId: 'RelationId',
    shipmentInfo: 'ShipmentInfo',
    sslCarrier: 'Carrier',
    smsId: 'SMS Id',
    sealNumber: 'Seal Number',
    swDeparture: 'E2E-Departure',
    swDischarge: 'E2E-Discharge',
    swFinalArrival: 'E2E-FinalArrival',
    swOG: 'E2E-OG',
    swER: 'E2E-ER',
    swStatus: 'E2E-Status',
    termLFD: 'LFD',
    trackDetails: 'Track Details',
    terminalInfo: 'Terminal Info',
    trackingNumber: 'BookID',
    transloadType: 'Transload Type',
    targetOG: 'TargetOG',
    targetER: 'TargetER',
    targetComplete: 'TargetComplete',
    termFeeTotal: 'TermFeeTotal',
    vessel: 'Vessel',
    voyage: 'Voyage',
    weight: 'Weight',
    whDelApp: 'whDelApp',
    wtUOM: 'Weight UOM'
  },
  dray: {
    bobTail: 'BobTail',
    chassisRent: 'Chassis Rent',
    costEstimate: 'Dray Cost Estimate',
    costEstimateAP: 'AP Estimate',
    costEstimateAR: 'AR Estimate',
    cleanTruck: 'Clean Truck',
    ctnrType: 'Container Size',
    chassisId: 'Chassis Id',
    chassisRecord: 'Chassis Rental Record',
    desType: 'Destination Type',
    dropType: 'Drop Type',
    drayage: 'Drayage',
    fromArea: 'From',
    fuelSurcharge: 'Fuel Surcharge',
    lspName: 'Dray Company',
    liveUnload: 'Live Unload',
    mapType: 'L2 to L3 Map Type',
    newQuote: 'New Quote',
    normTotal: 'Estimated Total',
    port: 'Port',
    pierPass: 'Pier Pass',
    pickupTime: 'pickupTime',
    portCongest: 'Port Congestion',
    reviewFullCard: 'Open to Review Full Rate Card',
    terminalFee: 'Terminal Fee',
    toArea: 'To'
  },
  account: {
    accountPayable: 'Account Payable',
    amount: 'Amount',
    adjustAmount: 'Adjust Amount',
    adjustReason: 'Adjust Reason',
    appTime: 'Appt Time',
    beginTime: 'Drive Begin',
    bobTail: 'Bobtail',
    billStatus: 'Bill Status',
    chassisRentDays: 'Chassis Rent Days',
    chassisRentCost: 'Chassis Rent Cost',
    chargeType: 'Charge Type',
    clientCode: 'Client Code',
    contractOwner: 'L2 Owner',
    dueDate: 'Due Date',
    drayage: 'Drayage Base Rate',
    driverName: 'Driver',
    endTime: 'Drive End',
    demurrageDays: 'DemurrageDays',
    finalAmount: 'Final Amount',
    fuelSurcharge: 'Fuel Surcharge',
    lowComboRate: 'Lowest ComboRate',
    lowComboMargin: 'Lowest ComboMargin',
    mileage: 'Mileage',
    payStatus: 'PayStatus',
    portCongest: 'Port Congest',
    prePull: 'Pre-Pull',
    quoteSummary: 'Quote Summary',
    shipStatus: 'Ship Status',
    statementId: 'Statement Id',
    srvType: 'Service Type',
    terminalFee: 'Terminal Fee',
    total: 'All Total',
    waitBegin: 'Wait Begin',
    waitEnd: 'Wait End',
    yardStorageDays: 'Yard Storage Days',
    yardStorageCost: 'Yard Storage Cost'
  },
  address: {
    check: 'Check',
    edit: 'Edit',
    deliveryAddress: 'Delivery Address',
    importAddress: 'Import From Address Book',
    newAddress: 'Create New Address',
    personName: 'Name',
    streetOne: 'Street One',
    streetTwo: 'Street Two',
    city: 'City',
    state: 'State',
    zip: 'Zipcode'
  },
  basicInfo: {
    addRecord: 'Add Record',
    key: 'File Type',
    value: 'File Name',
    addFile: 'Add File',
    updateInfo: 'Update Information',
    fileList: 'Document List'
  },
  excel: {
    show: 'Detail'
  },
  toast: {
    switchLangSuccess: 'Switch Language Success'
  },
  tagsView: {
    refresh: 'Refresh',
    closeRight: 'Close Rights',
    closeOther: 'Close Others'
  },
  theme: {
    themeColorChange: 'Theme Color Change',
    themeChange: 'Theme Change'
  },
  universal: {
    confirm: 'confirm',
    cancel: 'cancel'
  },
  navBar: {
    themeChange: 'Theme Modification',
    headerSearch: 'Page Search',
    screenfull: 'Full Screen Replacement',
    lang: 'Globalization',
    guide: 'Function Guide',
    home: 'Home',
    course: 'E-2-E homepage',
    logout: 'Log out'
  },
  guide: {
    close: 'close',
    next: 'next',
    prev: 'previous',
    guideTitle: 'guidance',
    guideDesc: 'Turn on the boot function',
    hamburgerTitle: 'Hamburger button',
    hamburgerDesc: 'Open and close the left menu',
    breadcrumbTitle: 'Bread crumbs',
    breadcrumbDesc: 'Indicates the current page position',
    searchTitle: 'search',
    searchDesc: 'Page link search',
    fullTitle: 'full screen',
    fullDesc: 'Page display switching',
    themeTitle: 'theme',
    themeDesc: 'Change project theme',
    langTitle: 'globalization',
    langDesc: 'Language switch',
    tagTitle: 'Label',
    tagDesc: 'Opened page tab',
    sidebarTitle: 'menu',
    sidebarDesc: 'Project function menu'
  },
  userInfo: {
    print: 'Print',
    title: 'Employee information',
    name: 'name',
    sex: 'gender',
    nation: 'nationality',
    mobile: 'phone number',
    province: 'Place of residence',
    date: 'Entry Time',
    remark: 'Remark',
    address: 'contact address',
    experience: 'Experience',
    major: 'Professional',
    glory: 'Glory',
    foot: 'Signature:___________Date:___________'
  },
  uploadExcel: {
    upload: 'Click upload',
    drop: 'Drag files here'
  },
  role: {
    buttonTxt: 'New Role',
    index: 'Serial number',
    name: 'name',
    desc: 'describe',
    action: 'operate',
    assignPermissions: 'assign permissions',
    removeRole: 'Delete role',
    dialogTitle: 'New role',
    dialogRole: 'Role Name',
    dialogDesc: 'Role description',
    updateRoleSuccess: 'User role updated successfully'
  },
  permission: {
    name: 'Authority name',
    mark: 'Authority ID',
    desc: 'Permission description'
  }
}
