import layout from '@/layout'

export default {
  path: '/dray',
  component: layout,
  redirect: '/dray/manage',
  name: 'drayOrder',
  meta: { title: 'drayOrder', icon: 'personnel' },
  children: [
    {
      path: '/dray/manage',
      component: () => import('@/views/dray/drayManage'),
      meta: {
        title: 'drayManage',
        icon: 'article'
      }
    },
    {
      path: '/rateInquiry/drayQuote',
      component: () =>
        import('@/views/rateinquiry/index'),
      meta: {
        title: 'drayQuote',
        icon: 'article-ranking'
      }
    },
    {
      path: '/record/orders',
      component: () =>
        import('@/views/myrecord/orders'),
      meta: {
        title: 'orders',
        icon: 'example'
      }
    },
    {
      path: '/dray/detail/:refId',
      component: () => import('@/views/dray/drayDetail'),
      props: true,
      meta: {
        title: 'drayDetail'
      }
    },
    {
      path: '/dray/getQuotes/:orderTag',
      component: () => import('@/views/dray/drayQuotes'),
      props: true,
      meta: {
        title: 'drayQuotes'
      }
    },
    {
      path: '/dray/manuelOrder/:orderTag',
      component: () => import('@/views/dray/manuelOrder'),
      props: true,
      meta: {
        title: 'manuelOrder'
      }
    },
    {
      path: '/dray/portInfoEdit/',
      component: () => import('@/components/portInfo'),
      props: true,
      meta: {
        title: 'portInfo'
      }
    },
    {
      path: '/dray/batchOrderList/:orderTag',
      component: () => import('@/components/orderRecord'),
      props: true,
      meta: {
        title: 'orderRecord'
      }
    }
  ]
}
