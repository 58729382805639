export default {
  login: {
    title: '用户登录',
    loginBtn: '登录',
    usernameRule: '用户名为必填项',
    passwordRule: '密码不能少于6位',
    desc: `
    测试权限账号：<br />
    提供三种权限账号：<br />
    1. 超级管理员账号： super-admin <br />
    2. 管理员账号：admin <br />
    3. 测试可配置账号：test <br />
    密码统一为：123456 <br />
    <br />
    导入用户账号：<br />
    可使用导入的用户名登录 <br />
    密码统一为：123456  <br />
    <b>注意：导入用户区分中英文库！！！！</b>
    `
  },
  chb: {
    annualBond: '年度保证金',
    clearanceBOL: '清关BOL数量',
    htsLines: 'HTS 行数',
    isfBOL: '进口商安全申报(ISF)BOL数量',
    isfBondBOL: 'ISF Bond BOL数量',
    ogaNum: '其他政府部门（OGA）申报个数'
  },
  chargeCode: {
    legcode: '细分行业编码',
    legname: '细分行业',
    legtype: '细分行业类别',
    legseq: '细分行业序列号',
    chargeType: '计费类别',
    chargeUOM: '计费单位',
    chargeDescription: '计费说明',
    responsible: 'Responsible'
  },
  route: {
    accounting: '财务',
    article: '文章',
    articleRanking: '文章排名',
    articleCreate: '创建文章',
    articleDetail: '文章详情',
    articleEditor: '文章编辑',
    address: '地址簿',
    billEditTemp: '账单编辑模板',
    Billing: '账单管理',
    billStatus: '账单动态',
    chargeCodeEdit: '计费项目管理',
    costRecord: '费用明细',
    chbQuote: '海关清关',
    chassisRent: '拖车车架租赁',
    comboQuote: '综合报价',
    dashboard: '总览',
    dray: '拖车',
    drayBatchQuote: '拖车批量报价',
    drayBillDetail: '拖车账单明细',
    drayDetail: '拖车详情',
    drayOrderDetail: '拖车订单详情',
    drayManage: '由追踪信息生成订单',
    driverRecord: '司机出车记录',
    drayQuote: '由报价生成订单',
    drayQuotes: '报价列表',
    drayOrder: '拖车订单',
    drayRC: '价卡管理',
    drayRCEdit: '价卡编辑',
    drayRCList: '价卡维护',
    e2eTrack: 'E2E追踪',
    e2eDetail: 'E2E详情',
    excelImport: 'Excel导入',
    interimAP: '应付账款草案',
    interimAR: '应收账款草案',
    invoice: '已开发票',
    invoiceEdit: '修改发票',
    myChart: '数据可视化',
    manuelOrder: '手工订单',
    legCodeManage: '细分行业代码维护',
    order: '订单',
    orderManage: '订单管理',
    orders: '我的订单',
    ofQuery: '海运',
    paramEdit: '参数管理',
    permissionList: '权限列表',
    portInfo: '港口信息',
    profile: '用户信息',
    quote: '价格查询',
    quotes: '我的报价',
    rate: '海运基础价',
    rcEditForQuote: '拖车即时价卡编辑',
    record: '我的记录',
    roleList: '角色列表',
    smsTrack: 'SMS 数据',
    track: '海运追踪',
    term49: 'Term49 数据',
    user: '用户',
    userManage: '员工管理',
    userInfo: '员工信息'
  },
  rate: {
    adjust: '调整/Bobtail',
    carrier: '承运公司',
    c1: '参数一',
    c2: '参数二',
    c20GP: 'c20GP',
    c40GP: 'c40GP',
    c40HQ: 'c40HQ',
    c45HQ: 'c45HQ',
    csopName: 'CSOP',
    createTime: '报价生成时间',
    createQuotePDF: '生成报价单',
    createOrder: '创建订单',
    chbSrv: '美国海关清关服务',
    clientName: '客户名',
    draySrv: '拖车服务',
    downloadCSV: '下载全部报价 CSV',
    dynamicTitle: '海运价格表',
    editComplete: '更新确认',
    endDate: '失效日期',
    generateOrder: '生成订单',
    getQuote: '报价查询',
    hisTag: '有效期标签',
    ipi: '铁路联运',
    item: '费用类别',
    l2CSVDownload: 'L2CSV下载',
    l2Owner: 'L2 持有人',
    l2Tag: 'L2 标签',
    l3CSVDownload: 'L3CSV下载',
    l3Owner: '合同持有人',
    newClient: '新客户',
    notes: '说明',
    oceanRateCap: '海运价格查询',
    oldClient: '现有客户',
    Operations: '操作',
    pageRemark: '注意事项',
    payTerm: '付款账期',
    primeKey: '主键',
    projectProfit: '预计利润',
    pol: '出发港',
    pod: '目的港',
    quotePayload: '报价单',
    quoteFrom: '报价公司',
    quoteTo: '客户',
    quoteType: '报价领域',
    qty: '数量',
    ratePerUOM: '单价',
    rcEdit: '修改价卡',
    reQuote: '重新询价',
    remark: '说明',
    startDate: '生效日期',
    sector: '节点',
    uom: '计价单位',
    validity: '有效期'
  },
  track: {
    basicInfo: '基本信息',
    bookId: '订单号',
    trackDetails: '详细信息',
    e2eTrackTitle: 'E2E-Track',
    dynamicTitle: '集装箱行程追踪',
    shipmentInfo: '船运信息',
    containerInfo: '集装箱信息',
    terminalInfo: '码头信息',
    drayServiceInfo: '拖车服务信息',
    danger: '危险品',
    goodsName: '商品名',
    emptyReturnInfo: '空柜返还',
    trackingNumber: '追踪号',
    client: '客户',
    podRegion: '目的港区域',
    ctnrCbm: '集装箱体积cbm',
    ctnrKg: '集装箱重量Kg',
    cartons: '柜内包装箱数',
    swStatus: '航运状态',
    drayType: '拖车服务类型',
    transloadType: '转运服务类型',
    sslCarrier: '轮船公司',
    vessel: '轮船名称',
    voyage: '航线',
    ddray: '拖车公司',
    destSite: '目的地',
    dCBPStatus: 'dCBPStatus',
    dFreightStatus: 'dFreightStatus',
    dTerminalPickupStatus: 'd码头提柜状态',
    swDeparture: 'swDeparture',
    swDischarge: 'E2E预测卸船时间',
    swFinalArrival: 'E2E预测最终抵达时间',
    swOG: 'E2E满柜拖出码头时间',
    swER: 'E2E预测还柜日期',
    fullGateIn: 'fullGateIn',
    etd: '预计出发',
    atd: '实际出发',
    discharge: '满柜卸船时间',
    onRail: 'onRail',
    offRail: 'offRail',
    termLFD: 'termLFD',
    fullGateOut: '满柜拖出码头时间',
    dWHCrdd: 'dWHCrdd',
    dWHArrival: '送柜到仓时间',
    dWHEmptyNotify: '仓库空柜通知',
    emptyReturnAppt: '空柜返还预约',
    emptyReturn: '空柜返还时间',
    emptyPickUpTime: '空柜提柜时间',
    targetOG: '期望满柜拖出码头时间',
    targetER: '期望空柜返还时间',
    targetComplete: '期望完成时间',
    smsId: '系统Id',
    sealNumber: '密封号',
    id: 'id',
    ctnrId: '集装箱号',
    mbl: '主提单号',
    pol: '装船港',
    pod: '目的港',
    eta: '预计到港',
    ata: '实际到港',
    firmsCode: 'firmsCode',
    dischargeTime: '卸船时间',
    holdStatus: '通关状态',
    pickupAva: '可否提柜',
    appointTime: '提柜预约时间',
    lfd: 'lfd',
    locationAtTerminal: 'locationAtTerminal',
    outGateTime: '出港时间',
    emptyReturnAppointTime: '空柜返还预约时间',
    emptyReturnTime: '空柜返还时间',
    drayCompany: '拖车公司',
    normTotal: '费用估算',
    oswCompany: '海外仓',
    ctnrSize: '集装箱型',
    whDelApp: '送仓预约',
    weight: '总重量',
    wtUOM: '重量单位',
    termFeeTotal: '码头费用',
    relationId: 'relationId',
    attr: '特征'
  },
  dray: {
    bobTail: 'BobTail',
    chassisRent: '车架租金',
    cleanTruck: '卡车清洁费',
    costEstimate: '拖车费用预估',
    costEstimateAP: '应付费用预估',
    costEstimateAR: '应收费用预估',
    ctnrType: '集装箱类型',
    chassisId: '拖车车架 Id',
    chassisRecord: '拖车车架租赁记录',
    desType: '目的地类别',
    dropType: '卸柜等候方式',
    drayage: '拖车基本费用',
    fromArea: '始发地',
    fuelSurcharge: '燃油附加费',
    mapType: 'L2 to L3 定价方法',
    newQuote: '重新询价',
    normTotal: '预计总额',
    lspName: '拖车公司',
    liveUnload: '卸柜等候费',
    pierPass: '码头通行费',
    pickupTime: '提柜时间',
    portCongest: '港口拥堵费',
    port: '港口',
    reviewFullCard: '查看完整价卡',
    toArea: '目的地',
    terminalFee: '码头费'
  },
  account: {
    accountPayable: '应付账款',
    amount: '金额',
    adjustAmount: '调整金额',
    adjustReason: '调整原因',
    appTime: '预约时间',
    beginTime: '出车起始时间',
    billStatus: '账单状态',
    bobTail: 'Bobtail',
    chassisRentDays: '车架租用天数',
    chassisRentCost: '车架租金',
    clientCode: '客户代码',
    chargeType: '费用类别',
    contractOwner: 'L2 持有主体',
    dueDate: '应付逾期日',
    drayage: '拖卡基本费用',
    demurrageDays: '滞港天数',
    driverName: '司机',
    endTime: '出车结束时间',
    fuelSurcharge: '燃油附加费',
    finalAmount: '最终金额',
    lowComboRate: '最低综合报价',
    lowComboMargin: '最低综合报价利润',
    mileage: '行驶里程',
    payStatus: '支付状态',
    portCongest: '港口拥堵费',
    prePull: '预提费',
    quoteSummary: '报价概况',
    shipStatus: '物流状态',
    statementId: '账单Id',
    srvType: '出车类别',
    terminalFee: '码头费用',
    total: '总额',
    waitBegin: '等候起始时间',
    waitEnd: '等候结束时间',
    yardStorageDays: '堆场存放天数',
    yardStorageCost: '堆场租金'
  },
  address: {
    check: '查看',
    edit: '编辑',
    deliveryAddress: '送柜地址',
    newAddress: '新建地址',
    importAddress: '从地址簿导入',
    personName: '名称',
    streetOne: '街道地址一',
    streetTwo: '街道地址二',
    city: '城市',
    state: '州',
    zip: '邮编'
  },
  basicInfo: {
    addRecord: '添加拖车记录',
    key: '文件类型',
    value: '文件名',
    addFile: '添加文件',
    updateInfo: '信息更新',
    fileList: '文件列表'
  },
  excel: {
    show: '详情'
  },
  toast: {
    switchLangSuccess: '切换语言成功'
  },
  tagsView: {
    refresh: '刷新',
    closeRight: '关闭右侧',
    closeOther: '关闭其他'
  },
  theme: {
    themeColorChange: '主题色更换',
    themeChange: '主题更换'
  },
  universal: {
    confirm: '确定',
    cancel: '取消'
  },
  navBar: {
    themeChange: '主题修改',
    headerSearch: '页面搜索',
    screenfull: '全屏替换',
    lang: '国际化',
    guide: '功能引导',
    home: '首页',
    course: '公司主页',
    logout: '退出登录'
  },
  guide: {
    close: '关闭',
    next: '下一个',
    prev: '上一个',
    guideTitle: '引导',
    guideDesc: '打开引导功能',
    hamburgerTitle: '汉堡按钮',
    hamburgerDesc: '打开和关闭左侧菜单',
    breadcrumbTitle: '面包屑',
    breadcrumbDesc: '指示当前页面位置',
    searchTitle: '搜索',
    searchDesc: '页面链接搜索',
    fullTitle: '全屏',
    fullDesc: '页面显示切换',
    themeTitle: '主题',
    themeDesc: '更换项目主题',
    langTitle: '国际化',
    langDesc: '语言切换',
    tagTitle: '标签',
    tagDesc: '已打开页面标签',
    sidebarTitle: '菜单',
    sidebarDesc: '项目功能菜单'
  },
  userInfo: {
    print: '打印',
    title: '员工信息',
    name: '姓名',
    sex: '性别',
    nation: '民族',
    mobile: '手机号',
    province: '居住地',
    date: '入职时间',
    remark: '备注',
    address: '联系地址',
    experience: '经历',
    major: '专业',
    glory: '荣耀',
    foot: '签字：___________日期:___________'
  },
  uploadExcel: {
    upload: '点击上传',
    drop: '将文件拖到此处'
  },
  role: {
    buttonTxt: '新增角色',
    index: '序号',
    name: '名称',
    desc: '描述',
    action: '操作',
    assignPermissions: '分配权限',
    removeRole: '删除角色',
    dialogTitle: '新增角色',
    dialogRole: '角色名称',
    dialogDesc: '角色描述',
    updateRoleSuccess: '用户角色更新成功'
  },
  permission: {
    name: '权限名称',
    mark: '权限标识',
    desc: '权限描述'
  }
}
