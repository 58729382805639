import layout from '@/layout'

export default {
  path: '/record',
  component: layout,
  redirect: '/record/address',
  name: 'record',
  meta: { title: 'record', icon: 'search' },
  children: [
    {
      path: '/record/myChart',
      component: () =>
        import('@/views/dashboard/myChart'),
      meta: {
        title: 'myChart',
        icon: 'chart'
      }
    },
    {
      path: '/record/address',
      component: () =>
        import('@/views/myrecord/address'),
      meta: {
        title: 'address',
        icon: 'article-ranking'
      }
    },
    {
      path: '/dray/driverRecord',
      component: () => import('@/views/dray/driverRecord'),
      meta: {
        title: 'driverRecord',
        icon: 'article'
      }
    },
    {
      path: '/record/chassisRent',
      component: () =>
        import('@/views/myrecord/chassisRent'),
      meta: {
        title: 'chassisRent',
        icon: 'role'
      }
    }
  ]
}
