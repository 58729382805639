import layout from '@/layout'

export default {
  path: '/Billing',
  component: layout,
  redirect: '/Billing/billStatus',
  name: 'Billing',
  meta: { title: 'billStatus', icon: 'nested' },
  children: [
    {
      path: '/Billing/billStatus',
      component: () => import('@/views/bill/index'),
      meta: {
        title: 'billStatus',
        icon: 'table'
      }
    },
    {
      path: '/Billing/costRecord',
      component: () => import('@/views/bill/costRecord'),
      meta: {
        title: 'costRecord',
        icon: 'article-ranking'
      }
    },
    {
      path: '/Billing/interimAP',
      component: () => import('@/views/bill/interimAP'),
      meta: {
        title: 'interimAP',
        icon: 'article-ranking'
      }
    },
    {
      path: '/Billing/interimAR',
      component: () => import('@/views/bill/interimAR'),
      meta: {
        title: 'interimAR',
        icon: 'article-ranking'
      }
    },
    {
      path: '/Billing/myInvoice',
      component: () => import('@/views/bill/myInvoice'),
      meta: {
        title: 'invoice',
        icon: 'article-ranking'
      }
    },
    {
      path: '/qboConnected',
      component: () => import('@/views/bill/qboConnected'),
      meta: {
        title: 'qboConnected'
      }
    },
    {
      path: '/Billing/editTemp/:myParam',
      name: 'billEditTemp',
      component: () => import('@/components/billEditTemp'),
      props: true,
      meta: {
        title: 'billEditTemp'
      }
    },
    {
      path: '/Billing/invoiceEdit/:myParam',
      name: 'invoiceEdit',
      component: () => import('@/views/bill/invoiceEdit'),
      props: true,
      meta: {
        title: 'billEditTemp'
      }
    },
    {
      path: '/Billing/drayBillDetail/:myParam',
      name: 'drayBillDetail',
      component: () => import('@/components/drayBillDetail'),
      props: true,
      meta: {
        title: 'drayBillDetail'
      }
    },
    {
      path: '/Billing/drayOrderDetail/:myParam',
      name: 'drayOrderDetail',
      component: () => import('@/views/rateinquiry/drayOrderDetail'),
      props: true,
      meta: {
        title: 'drayOrderDetail'
      }
    }
  ]
}
