export default {
  namespaced: true,
  state: {
    dateFlag: 'all',
    selectedPods: [''],
    trackingNumber: '',
    sortClause: 'swFinalArrival DESC'
  },
  mutations: {
    setDateFlag(state, payload) {
      state.dateFlag = payload
    },
    setSelectedPods(state, payload) {
      state.selectedPods = payload
    },
    setTrackingNumber(state, payload) {
      state.trackingNumber = payload
    },
    setSortClause(state, payload) {
      state.sortClause = payload
    }
  },
  actions: {
    asyncSetDateFlag(Store, params) {
      this.commit('e2etrack/setDateFlag', params)
    }
  },
  modules: {

  }
}
