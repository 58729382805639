import layout from '@/layout'

export default {
  path: '/track',
  component: layout,
  redirect: '/track/terminal49',
  name: 'track',
  meta: { title: 'track', icon: 'eye-open' },
  children: [
    {
      path: '/track/terminal49',
      component: () => import('@/views/track/index'),
      meta: {
        title: 'term49',
        icon: 'link'
      }
    },
    {
      path: '/e2eTrack',
      component: () => import('@/views/e2etrack/index'),
      meta: {
        title: 'smsTrack',
        icon: 'chart'
      }
    },
    {
      path: '/e2eTrack/info/:refId',
      component: () => import('@/views/e2eTrackInfo/index'),
      props: true,
      meta: {
        title: 'e2eDetail'
      }
    }
  ]
}
