// token
export const TOKEN = 'token'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'
// tags
export const TAGS_VIEW = 'tagsView'
// drayCostDetail
export const emptyDrayCostDetail =
{
  ctnrid: '',
  ctnrType: '',
  fromArea: '',
  toArea: '',
  lspName: '',
  weight: 0,
  drayage: 0,
  pierPass: 0,
  chassisRent: 0,
  overWeight: 0,
  triAxle: 0,
  terminalFee: 0,
  portCongest: 0,
  prePull: 0,
  fuelSurcharge: 0,
  liveUnload: 0,
  bobTail: 0,
  cleanTruck: 0,
  normTotal: 0,
  note: ''
}
export const newE2ETrack = { trackingNumber: '', client: '', mbl: '', ctnrId: '', pol: '', pod: '', podRegion: '', ctnrSize: '', ctnrCbm: 0, ctnrKg: 0, cartons: 0, goodsName: '', danger: '', swStatus: '', drayType: '', transloadType: '', sslCarrier: '', vessel: '', voyage: '', firmsCode: '', ddray: '', destSite: '', destCode: '', destAddr: '', erAddress: '', dCBPStatus: '', dFreightStatus: '', dTerminalPickupStatus: '', swDeparture: '', swDischarge: '', swFinalArrival: '', swOG: '', swER: '', fullGateIn: '', etd: '', atd: '', eta: '', ata: '', discharge: '', onRail: '', offRail: '', termLFD: '', fullGateOutz: '', dWHCrdd: '', dWHArrival: '', dWHEmptyNotify: '', emptyReturnAppt: '', emptyReturn: '', targetOG: '', targetER: '', targetComplete: '', smsId: '' }
export const newQuoteRecord = { id: 0, quoteFrom: '', quoteTo: '', quoteType: '', csopName: '', createTime: '', l2Owner: '', l2Tag: '' }
